
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 100;
  src: local("Saira ExtraCondensed Thin"),local(SairaExtraCondensed-Thin),url(//fonts.gstatic.com/s/sairaextracondensed/v3/fW6xdUWepu0r8HZYLdXhdRmahUNotGdjm2Ol6aGySJE.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/fW6xdUWepu0r8HZYLdXhdRT_xdTPGhRFczcoKJqBt8I.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/fW6xdUWepu0r8HZYLdXhddDiZyNu74_BoKVTfzyYybc.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 200;
  src: local("Saira ExtraCondensed ExtraLight"),local(SairaExtraCondensed-ExtraLight),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwffQ9lS_o7346kD7FAlvzI-v3rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfRW0W024EwMHRxmZfuY_nuL3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfYygVcJV2QBnGh8qOAwW5rn3rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 300;
  src: local("Saira ExtraCondensed Light"),local(SairaExtraCondensed-Light),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfWWd9KR2C2cM8bQReFAw28D3rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfQCx1EMbW1eFZOmb3pc2tbz3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfQPZRBeRMLwcSTzP_Mut-K73rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 400;
  src: local("Saira ExtraCondensed Regular"),local(SairaExtraCondensed-Regular),url(//fonts.gstatic.com/s/sairaextracondensed/v3/3XMbuc1UIdE_Bo4eJ-H3G3W5TFetRsmCWPgQ_1fJg50.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/3XMbuc1UIdE_Bo4eJ-H3G8BvcTyoFVl2hWxSOgahpEY.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/3XMbuc1UIdE_Bo4eJ-H3G0GJFlOJkO8mymA9tc33RXw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 500;
  src: local("Saira ExtraCondensed Medium"),local(SairaExtraCondensed-Medium),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfY9fgX4Ac45MKpp6UbA8Lmb3rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfROR75kcDjfuuNgpyTcfx6P3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfXnKrYhZhwOSDoAJuZTxzPn3rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 600;
  src: local("Saira ExtraCondensed SemiBold"),local(SairaExtraCondensed-SemiBold),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfWBW24ZT7ws6ZjQLRyrHeFv3rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfbpQX8I2DyXG4uHm2BpGsSH3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfUG6pymjL8bnnlcp-tN1f6r3rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 700;
  src: local("Saira ExtraCondensed Bold"),local(SairaExtraCondensed-Bold),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwff6CeLVY9wfEa4jvG67ma333rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwffHD1jZSi8KUxQcwKVvxv1r3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfQP6At_oeljG2S-gQitEVPn3rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 800;
  src: local("Saira ExtraCondensed ExtraBold"),local(SairaExtraCondensed-ExtraBold),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfb7wdVqcU-KDfXrziuNQqNz3rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfcSZbXHNn1xNkePBld0xfIj3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfbPbm5I-tNZh8XdYKOc0ifL3rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Saira Extra Condensed";
  font-style: normal;
  font-weight: 900;
  src: local("Saira ExtraCondensed Black"),local(SairaExtraCondensed-Black),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfRuDkrGkiHMpHa_tk8jod-j3rGVtsTkPsbDajuO5ueQw.eot?#) format("eot"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfYkF27DlXp6dWb0At9fH-RL3rGVtsTkPsbDajuO5ueQw.woff2) format("woff2"),url(//fonts.gstatic.com/s/sairaextracondensed/v3/XVu3ZHO65MpX5FDLl4hwfWVykXXYmWLPJNFn4yYxeer3rGVtsTkPsbDajuO5ueQw.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"),local(OpenSans-LightItalic),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxrXcjzEax2LfQAlK8DdMzhA.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxko2lTMeWA_kmIyWrkNCwPc.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxh_xHqYgAV9Bl_ZQbYUxnQU.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"),local(OpenSans-Italic),url(//fonts.gstatic.com/s/opensans/v15/xjAJXh38I15wypJXxuGMBmfQcKutQXcIrRfyR5jdjY8.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/xjAJXh38I15wypJXxuGMBobN6UDyHWBl620a-IRfuBk.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"),local(OpenSans-SemiBoldItalic),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxo8mkAiYpFywqG2RvpzBnTU.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxl2umOyRU7PgRiv8DXcgJjk.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxn5HxGBcBvicCpTp6spHfNo.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"),local(OpenSans-BoldItalic),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxrFt29aCHKT7otDW9l62Aag.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxolIZu-HDpmDIZMigmsroc4.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxjqR_3kx9_hJXbbyU8S6IN0.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: local("Open Sans ExtraBold Italic"),local(OpenSans-ExtraBoldItalic),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxphT2rkLFFMVLymfgfmWVtI.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxnibbpXgLHK_uTT48UMyjSM.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/PRmiXeptR36kaC0GEAetxkCDe67GEgBv_HnyvHTfdew.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"),local(OpenSans-Light),url(//fonts.gstatic.com/s/opensans/v15/DXI1ORHCpsQm3Vp6mXoaTXZ2MAKAc2x4R1uOSeegc5U.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),local(OpenSans-Regular),url(//fonts.gstatic.com/s/opensans/v15/cJZKeOuBrn4kERxqtaUH3fY6323mHUZFJMgTvxaG2iE.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),local(OpenSans-SemiBold),url(//fonts.gstatic.com/s/opensans/v15/MTP_ySUJH_bn48VBG8sNSnZ2MAKAc2x4R1uOSeegc5U.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),local(OpenSans-Bold),url(//fonts.gstatic.com/s/opensans/v15/k3k702ZOKiLJc3WVjuplzHZ2MAKAc2x4R1uOSeegc5U.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff) format("woff");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"),local(OpenSans-ExtraBold),url(//fonts.gstatic.com/s/opensans/v15/EInbV5DfGHOiMmvb1Xr-hnZ2MAKAc2x4R1uOSeegc5U.eot?#) format("eot"),url(//fonts.gstatic.com/s/opensans/v15/EInbV5DfGHOiMmvb1Xr-hugdm0LZdjqr5-oayXSOefg.woff2) format("woff2"),url(//fonts.gstatic.com/s/opensans/v15/EInbV5DfGHOiMmvb1Xr-hnhCUOGz7vYGh680lGh-uXM.woff) format("woff");
}
:root {
  --main-bg-color: #068587;
}
body {
  font-family: 'Open Sans', serif;
  padding-top: 54px;
  color: #333;
  font-size: 18px;
}
@media (min-width:992px) {
body {
    padding-top: 0;
    padding-left: 17rem
}
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Extra Condensed', serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #343a40
}
h2.front-page {
  text-transform: none;
}
h1 {
  font-size: 6rem;
  line-height: 5.5rem
}
h2 {
  font-size: 3.5rem
}
.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Saira Extra Condensed', serif;
  font-size: 1.35rem
}
.active {
  color: rgb(158, 241, 230) !important;
  text-decoration: none;
  font-weight: 900;
}
#sideNav .navbar-nav .nav-item .nav-link {
  font-weight: 600;
  text-transform: uppercase
}
@media (min-width:992px) {
#sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 17rem;
    height: 100vh;
    overflow: auto;
}
#sideNav .navbar-brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto auto 0;
    padding: .5rem
}
#sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: .5rem solid rgba(255, 255, 255, .2)
}
#sideNav .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    width: 100%;
    margin-bottom: auto
}
#sideNav .navbar-collapse .navbar-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
}
#sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block
}
#sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block
}
.image-container {
    margin-top: -50px;
}
}
section.content-section {
  border-bottom: 1px solid #dee2e6;
  padding-top: 5rem!important;
  padding-bottom: 5rem!important
}
@media (min-width:768px) {
section.content-section {
    min-height: 100vh;
}
.navHeader {
    border-bottom: 1px solid #aaa;
    padding-bottom: 20px;
}
.navHeader:hover {
    cursor: pointer;
}
.navHeader h1 {
    margin-top: 20px !important;
    font-size: 72px !important;
    line-height: 68px !important;
    color: #fff;
}
.navHeader h2 {
    margin-top: 20px !important;
    font-size: 22px !important;
    line-height: 22px !important;
    color: #ccc;
}
.navFooter {
    padding-top: 5px;
}
.navFooterLogo {
    width: 75px;
    padding-bottom: 15px !important;
    padding-top: 30px !important;
}
.navFooterCopy {
    font-size: 12px;
    color: #fff !important;
    padding-bottom: 15px !important;
}
.navFooterCopy a {

    color: #ccc !important;
}
}
@media (min-width:992px) {
section.content-section {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}
section.content-section.first {

    padding-top: 5rem!important;
}
}
.bg-primary {
  background-color: #068587 !important;
}
a {
  color: #444;
}
a.heavy {
  font-weight: 900;
}
a:active,
a:focus,
a:hover {
  color: #aaa;
  cursor: pointer;
}
#about-summit,
#about-cjcc,

#agenda {
  background: #35999B;
  color: white;
}
#about-summit h2,
#about-cjcc h2,

#agenda h2 {
  color: #fff !important;
}
#resources {
  background: #eee;
}
ul.sectionList li {
  margin-bottom: 20px;
}
.primary {
  color: #068587 !important;
}
.address {
  margin-top: 50px
}
.btn-registration {
  font-weight: 700;
  text-transform: uppercase;
  color: #333 !important;
}
.btn-registration:hover {
  color: #fff!important;
  background: #068587!important;
}
.readmore {
  margin-top: 80px;
}
.readmore .teaser {
  margin-bottom: 15px;
  font-weight: 900;
  color: #068587;
}
.readmore .arrow {

  color: #068587;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
.readmore:hover {
  cursor: pointer;
}
@keyframes bounce {
from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}
to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
}
}
@-webkit-keyframes bounce {
from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}
to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
}
}

#resources h4 {

  margin-bottom: 15px;
}
.card.presentation {
  padding: 25px;
  margin-top: 40px;
}
.card.presentation.first {

  margin-top: 0px;
}
a.presentation {
  font-weight: bold;
  color: #068587 !important;
}
